<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="anatarsale">
        <div class="anatarsale_tit">
            <el-switch
                    class="demo"
                    v-model='kaiguan'
                    @change="danwei_xuanze"
                    active-color="#9a84db"
                    inactive-color="#c4c7cd"
                    active-text="ON"
                    inactive-text="OFF">
                </el-switch>
            <span>默认启用</span>
            此开关是针对全体员工是否显示目标-销售模块，请慎重选择
        </div>
        <div class="tu3">
            <div class="tu3_tit">
                <p>产品销售规则(三)</p>
                <p>
                    <span @click="tu3_dianji_quanping"><img src='../../assets/quanping.png'>全屏</span>
                    <!-- <span><img src='../assets/biaoge.png'>表格</span> -->
                </p>
            </div>
            <div class="tu3_con">
                <tusan @tu3_nianfen="tu3_nianfen" :tu3_quanzi='tu3_quan'></tusan>
            </div>
        </div>
    </div>
</template>

<script>
import { query_module_status, update_module_status } from '../../api/api.js'
import tusan from './Figurethreea'
export default {
  name: 'anatarsale',
  data () {
    return {
      kaiguan: true,
      tu3_nianfen_zhi: '',
      tu3_quan: ''
    }
  },
  mounted () {
  },
  components: { tusan },
  created () {
    this.jichu()
  },
  watch: {
        
  },
  methods: {
    jichu () {
      if (this.$ent_id() != undefined && this.$ent_id() != null && this.$ent_id().length != 0) {
        query_module_status({
          data: {
            ent_id: this.$ent_id(),
            module_id: '1-2'
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10189) {
            const date = JSON.parse(res.data.body.data)
            this.kaiguan = date == 1
          } else if (res.data.code == 10190) {}
        })
      }
    },
    danwei_xuanze () {
      update_module_status({
        data: {
          active: this.kaiguan ? '1' : '0',
          ent_id: this.$ent_id(),
          module_id: '1-2',
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10191) {
          this.$message({
            message: '操作成功',
            type: 'success'
          })
        } else if (res.data.code == 10192) {}
      })
    },
    tu3_dianji_quanping () {
      if (this.tu3_nianfen_zhi != '') {
        console.log(1111)
        if (this.tu3_nianfen_zhi != '2') {
          this.tu3_quan = '1'
          console.log(2222)
        }
      } else {
        this.$message({
          message: '请选择年份，再进入全屏',
          type: 'warning'
        }) 
      }
    },
    tu3_nianfen: function (val) {
      if (val == 2) {
        this.tu3_quan = ''
      } else {
        this.tu3_nianfen_zhi = val
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 @import './quanxian.scss';
 .tu3{
    width:6rem;
    height:4.28rem;
    box-shadow:0 0 0.05rem 0.05rem rgba(217,217,217,0.5);
    border-radius: 0.04rem;
    padding: 0.2rem;
    background: #F8FAFD;
    .tu3_tit{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20.0.05rem;
        P{
            &:nth-child(1){
                font-size: 0.14rem;
                color:#4C4A4D;
            }
            &:nth-child(2){
                display: flex;
                align-items: center;
                span{
                    font-size: 0.1rem;
                    color:#4C4A4D;
                    margin-left: 0.23rem;
                    display: inline-block;
                    cursor: pointer;
                    img{
                        height:0.12rem;
                        width:0.12rem;
                        margin-right: 0.03rem;
                    }
                }
            }
        }
    }
    .tu3_con{
        height:3.88rem;
        width:100%;
        background:#fff;
        border-radius: 0.04rem;
    }
}
</style>
