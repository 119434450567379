<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:51:20
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="figurethreea">
        <div class="xiaoping">
            <div class="xuanze_nianfen">
                <p>
                    年份:
                    <el-select v-model="time" @change='xuanze_nianfen_biao'>
                        <el-option
                            v-for="(i,index) in year_list"
                            :key="index"
                            :label="i"
                            :value="i">
                        </el-option>
                    </el-select>
                </p>
                <p><span></span>&nbsp;&nbsp;允许售卖&nbsp;&nbsp;&nbsp;&nbsp;<span></span>&nbsp;&nbsp;禁止售卖</p>
                <p @click="dianji_baocun" :style="shifou_baocun?'background:#9a86db;border:0.01rem solid #9a86db;':'background:#B8C1CE;border:0.01rem solid #B8C1CE;'">保存</p>
            </div>
            <img v-if="time==''" style="margin-top:0.1rem;height:86%;width:100%;" src="../../assets/shili_tu3.png" alt="">
            <div v-if="time!=''" class="table_con">
                <el-table :data="neirong_list"
                    border
                    height="3rem"
                    :cell-style="cellStyle"
                    :header-cell-style="headerCellStyle"
                    >
                    <el-table-column v-for="(item, index) in biaotou_list"
                        :key="`col_${index}`"
                        :prop="biaotou_list[index].con"
                        :label="item.name">
                        <template slot-scope="props">
                            <div>
                                <span v-if="biaotou_list[index].con=='prod_name'">{{props.row[biaotou_list[index].con]}}</span>
                                <span v-if="props.row.prod_name=='合计'">{{props.row[biaotou_list[index].con]!='合计'?props.row[biaotou_list[index].con]:''}}</span>
                                <el-tooltip class="item" effect="dark" content="此产品销售规则已生成部门销售目标请勿修改 " placement="top-start">
                                    <span @click="dianji(props.row[biaotou_list[index].con],biaotou_list[index].con,props.row.prod_id)" :style="props.row[biaotou_list[index].con]==1&&props.row.prod_name!='合计'?'display:block;margin:0 auto;height:0.19rem;width:0.19rem;border-radius: 50%;background:#6ABFA7':(props.row[biaotou_list[index].con]==0&&props.row.prod_name!='合计'?'display:block;height:0.19rem;margin:0 auto;width:0.19rem;border-radius: 50%;background:#F96C6C':'')"></span>
                                </el-tooltip>
                                <input style="background:#D8E6F7;" type="number" onkeyup="this.value=this.value.replace(/\D/g,'')" onafterpaste="this.value=this.value.replace(/\D/g,'')" placeholder="请填写人数" v-if="props.row.prod_name=='编制人数'&&props.row[biaotou_list[index].con]!='编制人数'" v-model="props.row[biaotou_list[index].con]">
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="quanping" v-if="quan_zia==1">    <!--v-if="quan_zia==1"-->
            <div class="box">
                <div class="guanbi" @click="dianji_guanbi">X</div>
                <p class="box_text">产品销售规则(三)</p>
                <div class="box_con">
                    <div class="xuanze_nianfen">
                        <p>
                            年份:
                            <el-select v-model="time" @change='xuanze_nianfen_biao'>
                                <el-option
                                    v-for="(i,index) in year_list"
                                    :key="index"
                                    :label="i"
                                    :value="i">
                                </el-option>
                            </el-select>
                        </p>
                        <p><span></span>&nbsp;&nbsp;允许售卖&nbsp;&nbsp;&nbsp;&nbsp;<span></span>&nbsp;&nbsp;禁止售卖</p>
                        <p @click="dianji_baocun" :style="shifou_baocun?'background:#9a86db;border:0.01rem solid #9a86db;':'background:#B8C1CE;border:0.01rem solid #B8C1CE;'">保存</p>
                    </div>
                    <div class="table_con">
                        <el-table :data="neirong_list"
                            border
                            height="6rem"
                            :cell-style="cellStyle"
                            :header-cell-style="headerCellStyle"
                            >
                            <el-table-column v-for="(item, index) in biaotou_list"
                                :key="`col_${index}`"
                                :prop="biaotou_list[index].con"
                                :label="item.name">
                                <template slot-scope="props">
                                    <div>
                                        <span v-if="biaotou_list[index].con=='prod_name'">{{props.row[biaotou_list[index].con]}}</span>
                                        <span v-if="props.row.prod_name=='合计'">{{props.row[biaotou_list[index].con]!='合计'?props.row[biaotou_list[index].con]:''}}</span>
                                        <el-tooltip class="item" effect="dark" content="此产品销售规则已生成部门销售目标请勿修改 " placement="top-start">
                                            <span @click="dianji(props.row[biaotou_list[index].con],biaotou_list[index].con,props.row.prod_id)" :style="props.row[biaotou_list[index].con]==1&&props.row.prod_name!='合计'?'display:block;margin:0 auto;height:0.19rem;width:0.19rem;border-radius: 50%;background:#6ABFA7':(props.row[biaotou_list[index].con]==0&&props.row.prod_name!='合计'?'display:block;height:0.19rem;margin:0 auto;width:0.19rem;border-radius: 50%;background:#F96C6C':'')"></span>
                                        </el-tooltip>
                                        <input style="background:#D8E6F7;" type="number" onkeyup="this.value=this.value.replace(/\D/g,'')" onafterpaste="this.value=this.value.replace(/\D/g,'')" placeholder="请填写人数" v-if="props.row.prod_name=='编制人数'&&props.row[biaotou_list[index].con]!='编制人数'" v-model="props.row[biaotou_list[index].con]">
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </div>
  </div> 
</template>

<script>
import { check_prod_aim_and_con_amt, insert_as_prod_rule, query_as_prod_rule, query_prod_list_of_as_prod_aim, query_ent_dept_list_aim_tile, query_as_year_list } from '../../api/api.js'
export default {
  name: 'figurethreea',
  data () {
    return {
      time: '',
      year_list: [],
      biaotou_list: [
        {
          name: '产品',
          con: ''
        }
      ],
      neirong_list: [],
      shifou_baocun: false,
      quan_zia: ''
    }
  },
  computed: {
  },
  props: {
    //   tuchu_chuanshu:String,
    tu3_quanzi: String,
    required: true
  },
  watch: {
    tu3_quanzi (val) {
      console.log(val)
      this.quan_zia = val
    },
    neirong_list: {
      handler (newValue, oldValue) {
        this.chaxun_shifou_baocun()
      },
      deep: true
    }
  },
  created () {
    this.jichu()
  },
  methods: {
    //   基础信息
    jichu () {
      query_as_year_list({
        data: {
          ent_id: this.$ent_id(),
          aim_type: '2'
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10237) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          if (date != []) {
            this.year_list = []
            for (let i = 0; i < date.length; i++) {
              this.year_list.push(date[i] + '年')
            }
          }
        } else if (res.data.code == 10238) {}
      })
      this.bumen_liebiao_jiekou()
    },
    // 选择年份
    xuanze_nianfen_biao () {
      console.log(this.time)
      this.$emit('tu3_nianfen', this.time)
      this.chaxun_jiekou()
      // this.chanpin_liebiao_jiekou()
    },
    // 表头颜色
    headerCellStyle (row) { // 根据报警级别显示颜色
      return 'text-align:center;color:#1A2533;'
    },
    // color:#4C4A4D;font-size:0.12rem;
    cellStyle (row) {
      if (row.row.prod_name != undefined) {
        if (row.row.prod_name == '编制人数') {
          return 'color:#4C4A4D;font-size:0.12rem;text-align:center;background:#D8E6F7'
        }
        if (row.row.prod_name == '合计') {
          return 'color:#4C4A4D;font-size:0.12rem;text-align:center;background:#E2EFDA'
        } else {
          return 'color:#4C4A4D;font-size:0.12rem;text-align:center;'
        }
      }
    },
    // 部门列表接口
    bumen_liebiao_jiekou () {
      query_ent_dept_list_aim_tile({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10168) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          if (date != []) {
            this.biaotou_list = [
              {
                name: '产品',
                con: 'prod_name'
              }
            ]
            for (let i = 0; i < date.length; i++) {
              this.biaotou_list.push({
                name: date[i].label,
                con: date[i].dept_id
              })
            }
          }
        } else if (res.data.code == 10169) {}
      })
    },
    // 产品列表接口
    chanpin_liebiao_jiekou () {
      query_prod_list_of_as_prod_aim({
        data: {
          ent_id: this.$ent_id(),
          year: this.time.slice(0, 4)
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          if (date != []) {
            this.neirong_list = []
            const obj2 = {}
            const obj3 = {}
            const obj4 = {}
            for (let i = 0; i < date.length; i++) {
              const obj = {}
              for (let a = 0; a < this.biaotou_list.length; a++) {
                const zhi = this.biaotou_list[a].con
                if (this.biaotou_list[a].con == 'prod_name') {
                  obj.prod_id = date[i].prod_id
                  obj.prod_code = date[i].prod_code
                  obj['' + zhi] = date[i].prod_name
                  obj2['' + zhi] = '编制人数'
                  obj3['' + zhi] = '合计'
                } else {
                  obj.prod_id = date[i].prod_id
                  obj.prod_code = date[i].prod_code
                  obj['' + zhi] = '1'
                  obj2['' + zhi] = ''
                  obj3['' + zhi] = ''
                }
              }
              this.neirong_list.push(obj)
            }
            this.neirong_list.unshift(obj2)
            this.neirong_list.push(obj3)
            console.log(this.biaotou_list)
            setTimeout(() => {
              this.zidong_heji()
            }, 0)
          }
          const list = []
        } else if (res.data.code == 201) {}
      })
    },
    // 查询产品销售规则接口
    chaxun_jiekou () {
      query_as_prod_rule({
        data: {
          ent_id: this.$ent_id(),
          year: this.time.slice(0, 4)
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          if (date.length == 0) {
            this.chanpin_liebiao_jiekou()
          } else {
            this.neirong_list = []
            const obj2 = {}
            const obj3 = {}
            const obj4 = {}
            const zhixing_panduan = []
            for (let i = 1; i < date.length; i++) {
              if (Object.keys(date[i].map).length != 0) {
                zhixing_panduan.push('111')
              }
            }
            setTimeout(() => {
              if (zhixing_panduan.length > 0) {
                for (let i = 1; i < date.length; i++) {
                  const obj = {}
                  for (let a = 0; a < this.biaotou_list.length; a++) {
                    const zhi = this.biaotou_list[a].con
                    if (this.biaotou_list[a].con == 'prod_name') {
                      obj.prod_id = date[i].prod_id
                      obj.prod_code = date[i].prod_code
                      obj['' + zhi] = date[i].prod_name
                      obj3['' + zhi] = '合计'
                    } else {
                      obj.prod_id = date[i].prod_id
                      obj.prod_code = date[i].prod_code
                      obj['' + zhi] = date[i].map[zhi] != undefined ? date[i].map[zhi] : '0'
                      obj3['' + zhi] = ''
                    }
                    // date[i].add_map[zhi]!=undefined?date[i].add_map[zhi]:'2'
                    this.$set(this.biaotou_list[a], 'type', '2')
                  }
                  this.neirong_list.push(obj)
                }
              } else {
                for (let i = 1; i < date.length; i++) {
                  const obj = {}
                  zhixing_panduan.push('111')
                  for (let a = 0; a < this.biaotou_list.length; a++) {
                    const zhi = this.biaotou_list[a].con
                    if (this.biaotou_list[a].con == 'prod_name') {
                      obj.prod_id = date[i].prod_id
                      obj.prod_code = date[i].prod_code
                      obj['' + zhi] = date[i].prod_name
                      obj3['' + zhi] = '合计'
                    } else {
                      obj.prod_id = date[i].prod_id
                      obj.prod_code = date[i].prod_code
                      obj['' + zhi] = '1'
                      obj3['' + zhi] = ''
                    }
                    this.$set(this.biaotou_list[a], 'type', '1')
                  }
                  this.neirong_list.push(obj)
                }
              }
              for (let a = 0; a < this.biaotou_list.length; a++) {
                const zhi = this.biaotou_list[a].con
                if (this.biaotou_list[a].con == 'prod_name') {
                  obj2['' + zhi] = '编制人数'
                } else {
                  obj2['' + zhi] = date[0].map[zhi]
                }
                // if(date[0].map[zhi]!=undefined){
                //     this.$set(obj2,'type','1')
                // }else{
                //     this.$set(obj2,'type','2')
                // }
              }
              this.neirong_list.unshift(obj2)
              this.neirong_list.push(obj3)
              console.log(this.biaotou_list)
              setTimeout(() => {
                this.zidong_heji()
              }, 0)
            }, 0)
          }
        } else if (res.data.code == 201) {}
      })
    },
    // 合计的值，进行监听
    zidong_heji () {
      const bumen_id = []
      for (let i = 1; i < this.biaotou_list.length; i++) {
        bumen_id.push(this.biaotou_list[i].con)
      }
      const zong = ''
      for (let a = 0; a < bumen_id.length; a++) {
        let list1 = ''
        for (let i = 0; i < this.neirong_list.length; i++) {
          if (this.neirong_list[i].prod_name != '合计' && this.neirong_list[i].prod_name != '编制人数') {
            if (this.neirong_list[i][bumen_id[a]] == 1) {
              list1 = Number(list1) + Number(this.neirong_list[i][bumen_id[a]])
            }
          }
        }
        this.neirong_list[this.neirong_list.length - 1][bumen_id[a]] = list1 + '/' + (this.neirong_list.length - 2)
      }
    },
    // 计算
    jisuan () {
    },
    // 点击关闭
    dianji_guanbi () {
      // this.$router.push('/aspreackeven')
      this.quan_zia = ''
      this.$emit('tu3_nianfen', '2')
      sessionStorage.setItem('pc_mlbb_mubiao_biao', '-1')
    },
    dianji (i, b, c) {
      console.log(i)
      console.log(b)
      console.log(c)
      if (i == 0) {
        for (let a = 0; a < this.neirong_list.length; a++) {
          if (this.neirong_list[a].prod_name != '编制人数' && this.neirong_list[a].prod_name != '合计') {
            if (c == this.neirong_list[a].prod_id) {
              if (i == 1) {
                this.neirong_list[a][b] = '0'
              } else if (i == 0) {
                this.neirong_list[a][b] = '1'
              }
            }
            this.zidong_heji()
          }
        }
      } else if (i == 1) {
        check_prod_aim_and_con_amt({
          data: {
            ent_id: this.$ent_id(),
            dept_id: b,
            prod_id: c,
            year: this.time.slice(0, 4)
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10248) {
            this.$message({
              message: '请先去修改部门目标等于或小于实际发生的合同金额',
              type: 'warning'
            }) 
          } else if (res.data.code == 10249) {
            for (let a = 0; a < this.neirong_list.length; a++) {
              if (this.neirong_list[a].prod_name != '编制人数' && this.neirong_list[a].prod_name != '合计') {
                if (c == this.neirong_list[a].prod_id) {
                  if (i == 1) {
                    this.neirong_list[a][b] = '0'
                  } else if (i == 0) {
                    this.neirong_list[a][b] = '1'
                  }
                }
                this.zidong_heji()
              }
            }
          }
        })
      }
    },
    // 点击保存
    dianji_baocun () {
      if (this.shifou_baocun) {
        const list = []
        for (let i = 1; i < this.neirong_list.length - 1; i++) {
          for (let a = 1; a < this.biaotou_list.length; a++) {
            list.push({
              prod_code: this.neirong_list[i].prod_code,
              prod_id: this.neirong_list[i].prod_id,
              dept_id: this.biaotou_list[a].con,
              flag_sale: this.neirong_list[i][this.biaotou_list[a].con],
              type: this.biaotou_list[a].type
            })
          }
        }
        const list2 = []
        for (let i = 1; i < this.biaotou_list.length; i++) {
          list2.push({
            dept_id: this.biaotou_list[i].con,
            staff_num: this.neirong_list[0][this.biaotou_list[i].con] + ''
          })
        }
        console.log(list)
        console.log(list2)
        insert_as_prod_rule({
          data: {
            list: list,
            staff_list: list2,
            ent_id: this.$ent_id(),
            year: this.time.slice(0, 4),
            user_id: this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.$message({
              message: '保存成功',
              type: 'success'
            }) 
            this.shifou_baocun = false
          } else if (res.data.code == 500) {}
        })
      } else {
        this.$message({
          message: '您已保存过或未填写完信息',
          type: 'warning'
        }) 
      }
    },
    // 查询是否可以保存
    chaxun_shifou_baocun () {
      const zhi = []
      console.log(this.neirong_list[0])
      for (let i = 0; i < this.biaotou_list.length; i++) {
        if (this.neirong_list[0][this.biaotou_list[i].con] == '' || this.neirong_list[0][this.biaotou_list[i].con] == undefined) {
          zhi.push(1)
        }
      }
      console.log(zhi)
      if (zhi.length == 0) {
        this.shifou_baocun = true
      } else {
        this.shifou_baocun = false
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.figurethreea {
    height:100%;
    width:100%;
    // position: relative;
    .xiaoping{
        height:100%;
        width:100%;
        .xuanze_nianfen{
            padding-top:0.18rem;
            margin-left: 0.16rem;
            margin-right: 0.16rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
                &:nth-child(1){
                    font-size: 0.12rem;
                    color:#4C4A4D;
                }
                &:nth-child(2){
                    font-size: 0.12rem;
                    color:#1A2533;
                    display: flex;
                    align-items: center;
                    span{
                        height:0.18rem;
                        width:0.18rem;
                        border-radius: 50%;
                        display: inline-block;
                        &:nth-child(1){
                            background:#6ABFA7;
                        }
                        &:nth-child(2){
                            background:#F96C6C;
                        }
                    }
                }
                &:nth-child(3){
                    font-size: 0.12rem;
                    color:#fff;
                    padding: 0.03rem 0.08rem;
                    border-radius: 0.03rem;
                    cursor: pointer;
                }
            }
        }
        .table_con{
            width:95%;
            margin: 0 auto;
            height:3rem;
            margin-top: 0.11rem;
            overflow-y:auto;
            &::-webkit-scrollbar {
                width: 0.2rem;
                height: 0.08rem;
            }
            
            &::-webkit-scrollbar-thumb {
                border-radius: 0.12rem;
                border: 0.06rem solid rgba(0, 0, 0, 0);
                box-shadow: 0.08rem 0 0 #A5ADB7 inset;
            }
            
            &::-webkit-scrollbar-thumb:hover {
                box-shadow: 0.08rem 0 0 #4A4A4A inset;
            }
        }
    }
    .quanping{
        height:100%;
        width:100%;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
        margin: 0;
        z-index:999;
        background: rgba(0,0,0,0.8);
        .box{
            position: relative;
            height:90%;
            width: 14rem;
            left:50%;
            top:5%;
            margin-left:-8rem;
            background: #F1F3F9;
            .box_text{
                position: absolute;
                font-size: 0.17rem;
                color:#4C4A4D;
                left:5%;
                top:3%;
            }
            .guanbi{
                position: absolute;
                top:-0.2rem;
                right:-0.2rem;
                height:0.6rem;
                width: 0.6rem;
                border-radius: 50%;
                background: #fc6a6a;
                color:#fff;
                text-align: center;
                font-size: 0.3rem;
                line-height: 0.6rem;
                box-shadow:0 0 0.05rem 0.05rem rgba(27, 27, 27, 0.5);
                cursor: pointer;
            }
            .box_con{
                position: relative;
                height:86%;
                width: 90%;
                left:5%;
                top:7%;
                background: #fff;
                .xuanze_nianfen{
                    padding-top:0.18rem;
                    margin:0 2.5%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    p{
                        &:nth-child(1){
                            font-size: 0.12rem;
                            color:#4C4A4D;
                        }
                        &:nth-child(2){
                            font-size: 0.12rem;
                            color:#1A2533;
                            display: flex;
                            align-items: center;
                            span{
                                height:0.18rem;
                                width:0.18rem;
                                border-radius: 50%;
                                display: inline-block;
                                &:nth-child(1){
                                    background:#6ABFA7;
                                }
                                &:nth-child(2){
                                    background:#F96C6C;
                                }
                            }
                        }
                        &:nth-child(3){
                            font-size: 0.12rem;
                            color:#4C4A4D;
                            padding: 0.03rem 0.08rem;
                            border-radius: 0.03rem;
                            cursor: pointer;
                        }
                    }
                }
                .table_con{
                    width:95%;
                    margin: 0 auto;
                    margin-top: 0.11rem;
                }
            }
        }
    }
    @media screen and (max-width: 1600px) {
        .quanping {
            width:1600px;
        }
    }
}
</style>
